import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiLavandierService } from '../../lib/lib-ngx/web-services/api-lavandier.service';

@Pipe({
  name: 'lmImage'
})
export class LmImagePipe implements PipeTransform {

  constructor(
    private apiLavandierService: ApiLavandierService,
  ) {
  }

  transform(incidentImageId: number) {

    return new Observable<string>((observer) => {
      this.apiLavandierService.getIncidentImage(incidentImageId)
        .subscribe(
          (data: Blob) => {
            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onloadend = function () {
              observer.next(reader.result);
            };
          },
        );

      return {
        unsubscribe() {
        }
      };
    });
  }
}
