import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { Cacheable } from 'ngx-cacheable';

import { ApiLavandierService } from '../../lib/lib-ngx/web-services/api-lavandier.service';

@Pipe({
  name: 'lmUpload'
})
export class LmUploadPipe implements PipeTransform {

  constructor(private apiLavandierService: ApiLavandierService) {
  }

  @Cacheable({
    maxCacheCount: 9999,
  })
  transform(uploadId: number) {
    return new Observable<string>((observer) => {
      this.apiLavandierService.getUpload(uploadId)
        .subscribe((data: Blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onloadend = () => {
            observer.next(reader.result);
          };
        });

      return {
        unsubscribe() {
        }
      };
    });
  }
}
