import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  private url = 'http://localhost:4242';

  constructor(
    private http: HttpClient,
  ) {
  }

  private readFileAsync(file): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  }

  public printObservableList(observableList: Observable<any>[]) {
    return forkJoin(observableList)
      .pipe(
        mergeMap((blobList: Blob[]) => Promise.all(blobList.map(async blob => await this.readFileAsync(blob)))),
        tap(resultList => {
          const desktopObservableList = [];
          resultList.forEach(result => {
            desktopObservableList.push(this.http.post(`${this.url}/print`, {img: result}));
          });

          return forkJoin(desktopObservableList).subscribe();
        }),
      );
  }
}
