import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { WebcamImage } from 'ngx-webcam';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { OrderArticleTrackingDefectOrigin } from '../../../../lib/lib-shared/types/OrderArticleTrackingDefectOrigin';
import { OrderArticleTrackingDefectStation } from '../../../../lib/lib-shared/types/OrderArticleTrackingDefectStation';
import { OrderArticleTrackingDefectType } from '../../../../lib/lib-shared/types/OrderArticleTrackingDefectType';
import { OrderArticleTrackingDefectOriginMap } from '../../../../lib/lib-ngx/web-services/TypeMap/OrderArticleTrackingDefectOrigin';
import { OrderArticleTrackingDefectStationMap } from '../../../../lib/lib-ngx/web-services/TypeMap/OrderArticleTrackingDefectStation';
import { OrderArticleTrackingDefectTypeMap } from '../../../../lib/lib-ngx/web-services/TypeMap/OrderArticleTrackingDefectType';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { ImagePreviewModalComponent } from '../image-preview-modal/image-preview-modal.component';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'lm-order-article-tracking-defect-modal',
  templateUrl: './order-article-tracking-defect-modal.component.html',
  styleUrls: ['./order-article-tracking-defect-modal.component.scss']
})
export class OrderArticleTrackingDefectModalComponent implements OnInit {
  public OrderArticleTrackingDefectOriginMap = OrderArticleTrackingDefectOriginMap;
  public OrderArticleTrackingDefectStationMap = OrderArticleTrackingDefectStationMap;
  public OrderArticleTrackingDefectTypeMap = OrderArticleTrackingDefectTypeMap;
  public OrderArticleTrackingDefectOrigin = OrderArticleTrackingDefectOrigin;
  public OrderArticleTrackingDefectTypeImgMap = new Map([
    [OrderArticleTrackingDefectType.STAIN, 'assets/img/modals/order-article-tracking-defect-modal/STAIN.png'],
    [OrderArticleTrackingDefectType.DISCOLORATION_COLORATION, 'assets/img/modals/order-article-tracking-defect-modal/DISCOLORATION_COLORATION.png'],
    [OrderArticleTrackingDefectType.BURN, 'assets/img/modals/order-article-tracking-defect-modal/BURN.png'],
    [OrderArticleTrackingDefectType.RIPPED, 'assets/img/modals/order-article-tracking-defect-modal/RIPPED.png'],
    [OrderArticleTrackingDefectType.UNSTITCHED, 'assets/img/modals/order-article-tracking-defect-modal/UNSTITCHED.png'],
    [OrderArticleTrackingDefectType.SHRINKAGE, 'assets/img/modals/order-article-tracking-defect-modal/SHRINKAGE.png'],
    [OrderArticleTrackingDefectType.MISSING_DAMAGED_ACCESSORY, 'assets/img/modals/order-article-tracking-defect-modal/MISSING_DAMAGED_ACCESSORY.png'],
    [OrderArticleTrackingDefectType.WEAR, 'assets/img/modals/order-article-tracking-defect-modal/WEAR.png'],
    [OrderArticleTrackingDefectType.SENSITIVE_ARTICLE, 'assets/img/modals/order-article-tracking-defect-modal/SENSITIVE_ARTICLE.png'],
  ]);

  public defectStation: OrderArticleTrackingDefectStation = null;

  public refFormControl = new FormControl();
  @ViewChild('refInput') refInput: ElementRef;

  public videoOptions: MediaTrackConstraints = {
    width: {ideal: 1920},
    height: {ideal: 1080}
  };
  private trigger: Subject<void> = new Subject<void>();
  public webcamImage: WebcamImage = null;

  public orderArticleTracking = null;

  public orderArticleTrackingDefectParent = null;
  public defectOrigin: OrderArticleTrackingDefectOrigin = null;

  public create = false;
  public defectType: OrderArticleTrackingDefectType = null;

  constructor(
    public activeModal: NgbActiveModal,
    private apiLavandierService: ApiLavandierService,
    private modalService: NgbModal,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
  ) {
  }

  ngOnInit() {
    this.reset();

    this.refFormControl.valueChanges
      .pipe(debounceTime(200),
        distinctUntilChanged(),
        switchMap(ref => {
          if (ref && ref !== '') {
            this.webcamImage = null;
            this.orderArticleTracking = null;
            this.orderArticleTrackingDefectParent = null;
            this.defectOrigin = null;
            this.create = false;
            this.defectType = null;
            return this.apiLavandierService.getOrderArticleTrackingRefDetails(ref)
              .pipe(
                catchError(() => {
                  return of(null);
                })
              );
          } else {
            return of(null);
          }
        }),
      )
      .subscribe(orderArticleTracking => {
        this.reset(false);
        if (orderArticleTracking) {
          this.orderArticleTracking = orderArticleTracking;

          this.orderArticleTracking.orderArticleTrackingDefectList
            .forEach(orderArticleTrackingDefect => {
              if (!orderArticleTrackingDefect.parentId) {
                orderArticleTrackingDefect.parentId = orderArticleTrackingDefect.id;
              }
            });
        } else {
          this.reset(true);
        }
      });
  }

  reset(refError = false) {
    this.refFormControl.enable({emitEvent: false});
    this.getRefInputFocus();

    if (!refError) {
      this.refFormControl.reset();
    }
  }

  getRefInputFocus() {
    setTimeout(() => this.refInput.nativeElement.focus());
  }

  selectParent(event) {
    if (event.type === 'click') {
      let orderArticleTrackingDefect = event.row;
      if (orderArticleTrackingDefect.parentId) {
        for (const oatd of this.orderArticleTracking.orderArticleTrackingDefectList) {
          if (oatd.id === orderArticleTrackingDefect.parentId) {
            orderArticleTrackingDefect = oatd;
            break;
          }
        }
      }
      this.orderArticleTrackingDefectParent = orderArticleTrackingDefect;
      let i = 0;
      for (const oatd of this.orderArticleTracking.orderArticleTrackingDefectList) {
        if (oatd.parentId === this.orderArticleTrackingDefectParent.id) {
          i++;
        }
      }
      if (i >= 4) {
        this.orderArticleTrackingDefectParent = null;
        const confirmationModal = this.modalService.open(ConfirmationModalComponent, {
          size: 'lg',
          centered: true,
          backdrop: 'static',
          keyboard: false,
        });
        confirmationModal.componentInstance.confirmationDesc = 'Nombre maximum de retraitements atteint';
        confirmationModal.componentInstance.validateButtonText = 'Ok';
        confirmationModal.result
          .then(() => this.renderer.addClass(this.document.body, 'modal-open'));
      }
    }
  }

  openImagePreviewModal(event, orderArticleTrackingDefect) {
    event.stopPropagation();
    const imagePreviewModal = this.modalService.open(ImagePreviewModalComponent, {size: 'lg'});
    imagePreviewModal.componentInstance.imageList = [orderArticleTrackingDefect];
    imagePreviewModal.componentInstance.selectedImage = orderArticleTrackingDefect;

    imagePreviewModal.result
      .then(() => this.renderer.addClass(this.document.body, 'modal-open'))
      .catch(() => this.renderer.addClass(this.document.body, 'modal-open'));
  }

  openCreate() {
    this.create = true;
  }

  showReprocessingButton(): boolean {
    let i = 0;
    for (const oatd of this.orderArticleTracking.orderArticleTrackingDefectList) {
      if (oatd.parentId === this.orderArticleTrackingDefectParent.id) {
        i++;
      }
    }
    return i < 3;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
  }

  takePicture() {
    if (this.webcamImage) {
      this.webcamImage = null;
    } else {
      this.trigger.next();
    }
  }

  async validate() {
    const dataUrl = this.webcamImage.imageAsDataUrl;
    const blob = await (await fetch(dataUrl)).blob();
    const type = dataUrl.substring(dataUrl.indexOf(':') + 1, dataUrl.indexOf(';'));
    const ext = type.substring(type.indexOf('/') + 1);
    const file = new File([blob], `webcam.${ext}`, {
      type: type,
      lastModified: new Date().getTime(),
    });
    this.apiLavandierService.putOrderArticleTrackingIdDefect(this.orderArticleTracking.id, file, {
      parentId: this.orderArticleTrackingDefectParent ? this.orderArticleTrackingDefectParent.id : null,
      origin: this.defectOrigin ? this.defectOrigin : OrderArticleTrackingDefectOrigin.NEW_DEFECT,
      station: this.defectStation,
      type: this.orderArticleTrackingDefectParent ? this.orderArticleTrackingDefectParent.type : this.defectType,
    })
      .subscribe(() => this.activeModal.close(true));
  }
}
