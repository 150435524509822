import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../web-services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TimeoutLoginService {

  private hours = 0.2;
  private timeoutId: any;
  private timeoutSecondId: any;

  private generateModal: () => Promise<boolean>;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
  }

  setModal(generateModal: () => Promise<boolean>) {
    this.generateModal = generateModal;
  }

  event() {
    clearTimeout(this.timeoutId);
    clearTimeout(this.timeoutSecondId);
    this.timeoutId = setTimeout(async () => {
      this.timeoutSecondId = setTimeout(() => {
        this.timeout();
      }, 10 * 1000);
      const result = await this.generateModal();
      if (result) {
        this.timeout();
      } else {
        clearTimeout(this.timeoutSecondId);
        this.event();
      }
    }, this.hours * 60 * 60 * 1000);
  }

  timeout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
