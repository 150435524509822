import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { WebcamImage } from 'ngx-webcam';

@Component({
  selector: 'lm-webcam-modal',
  templateUrl: './webcam-modal.component.html',
  styleUrls: ['./webcam-modal.component.scss']
})
export class WebcamModalComponent implements OnInit {

  public videoOptions: MediaTrackConstraints = {
    width: {ideal: 1920},
    height: {ideal: 1080}
  };
  private trigger: Subject<void> = new Subject<void>();
  public webcamImage: WebcamImage = null;

  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit() {
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
  }

  takePicture() {
    if (this.webcamImage) {
      this.webcamImage = null;
    } else {
      this.trigger.next();
    }
  }

  async validate() {
    const dataUrl = this.webcamImage.imageAsDataUrl;
    const blob = await (await fetch(dataUrl)).blob();
    const type = dataUrl.substring(dataUrl.indexOf(':') + 1, dataUrl.indexOf(';'));
    const ext = type.substring(type.indexOf('/') + 1);
    const file = new File([blob], `webcam.${ext}`, {
      type: type,
      lastModified: new Date().getTime(),
    });

    this.activeModal.close(file);
  }
}
