import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';

import { ListToMap } from '../../../lib/lib-ngx/utils/ListToMap';
import { ApiLavandierService } from '../../../lib/lib-ngx/web-services/api-lavandier.service';
import { InvoiceDeleteArticleModalComponent } from '../modals/invoice-delete-article-modal/invoice-delete-article-modal.component';
import { ReprintArticleTrackingComponent } from '../modals/reprint-article-tracking/reprint-article-tracking.component';
import { StandByModalComponent } from '../modals/stand-by-modal/stand-by-modal.component';
import { PrintService } from '../../../lib/lib-ngx/web-services/print.service';
import { ErrorService } from '../../../lib/lib-ngx/services/error.service';
import {
  OrderArticleTrackingDefectModalComponent
} from '../modals/order-article-tracking-defect-modal/order-article-tracking-defect-modal.component';
import { OrderArticleTrackingDefectStation } from '../../../lib/lib-shared/types/OrderArticleTrackingDefectStation';
import { WebcamModalComponent } from '../modals/webcam-modal/webcam-modal.component';
import { ArticleFamilyType } from '../../../lib/lib-shared/types/ArticleFamilyType';
import { ArticleUnity } from '../../../lib/lib-shared/types/ArticleUnity';
import { PRICEMULTIPLIER } from '../../../lib/lib-shared/defines';
import { ConfirmationModalComponent } from '../modals/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'lm-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {
  public ArticleFamilyType = ArticleFamilyType;
  public ArticleUnity = ArticleUnity;
  public PRICEMULTIPLIER = PRICEMULTIPLIER;

  public articleFamilyList = [];
  public articleFamilyMap = new Map();
  public articleFamilyByTypeAndUserTypeList = [];
  public articleList = [];
  public articleMap = new Map();
  public articleByFamilyMap = new Map();
  public articleOptionList = [];
  public orderBag = null;
  public orderBagId = null;
  public articleFamilySelected = null;
  public articleSelected = null;
  public invoiceForm: FormGroup;
  public pinForm: FormGroup;
  public chooseArticle = false;
  public colorList = [
    {
      name: 'Jaune',
      value: '#f2e15c',
    },
    {
      name: 'Vert',
      value: '#08c299',
    },
    {
      name: 'Bleu',
      value: '#4a90e2',
    },
    {
      name: 'Marron',
      value: '#8b572a',
    },
    {
      name: 'Orange',
      value: '#ff9900',
    },
    {
      name: 'Rouge',
      value: '#f04c5e',
    },
    {
      name: 'Blanc',
      value: '#ffffff',
    },
    {
      name: 'Noir',
      value: '#000000',
    },
    {
      name: 'Violet',
      value: '#9500ff',
    },
  ];
  public orderArticleSelected = null;

  @ViewChild('commentPopover') public commentPopover;

  constructor(
    private router: Router,
    public domSanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private apiLavandierService: ApiLavandierService,
    private printService: PrintService,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      (params: any) => {
        this.orderBagId = params.orderBagId;
        this.resetData();
        this.loadData()
          .subscribe(() => {
            this.loadOrderBagData().subscribe(() => {
              if (this.orderBag) {
                this.init();
                if (this.orderBag.comment !== null) {
                  this.commentPopover.open();
                }
              }
            });
          });
      }
    );
  }

  resetData() {
    this.articleFamilyList = [];
    this.articleFamilyMap = new Map();
    this.articleFamilyByTypeAndUserTypeList = [];
    this.articleList = [];
    this.articleMap = new Map();
    this.articleByFamilyMap = new Map();
    this.articleOptionList = [];
    this.orderBag = null;
    this.articleFamilySelected = null;
    this.articleSelected = null;
    this.chooseArticle = false;

    this.invoiceForm = this.fb.group({});
    this.pinForm = this.fb.group({});
  }

  loadData(): Observable<any> {
    return forkJoin([
      this.apiLavandierService.getArticleList(),
      this.apiLavandierService.getArticleFamilyList(),
      this.apiLavandierService.getArticleOptionList(),
    ])
      .pipe(
        map(([articleList, articleFamilyList, articleOptionList]) => {
          this.articleList = articleList as any[];
          this.articleMap = ListToMap.convert(articleList as any[]);
          this.articleByFamilyMap = ListToMap.list(articleList as any[], 'articleFamilyId');
          this.articleFamilyList = articleFamilyList as any[];
          this.articleFamilyMap = ListToMap.convert(articleFamilyList as any[]);
          this.articleOptionList = articleOptionList as any[];
        })
      );
  }

  loadOrderBagData(): Observable<any> {
    return this.apiLavandierService.getOrderBagDetails(this.orderBagId)
      .pipe(
        map((orderBag: any) => {
          if (orderBag === null) {
            this.router.navigate(['/scan-bag']);
            return;
          }
          this.orderBag = orderBag;
        })
      );
  }

  init() {
    this.buildForm();
    this.initArticleFamilyByTypeAndUserTypeList();
  }

  buildForm() {
    this.invoiceForm = this.fb.group({
      quantity: [1],
    });
    this.pinForm = this.fb.group({
      pinNumber: ['', Validators.required],
      pinColor: ['', Validators.required]
    });
    this.articleOptionList.forEach(articleOption => this.invoiceForm.addControl(articleOption.id, new FormControl(false)));
    this.orderBag.articleOptions.forEach(articleOption => {
      this.invoiceForm.controls[articleOption.id].setValue(true);
      this.invoiceForm.controls[articleOption.id].disable();
    });
  }

  initArticleFamilyByTypeAndUserTypeList() {
    this.articleFamilyList.forEach((articleFamily) => {
      if (articleFamily.type === this.orderBag.type && this.articleByFamilyMap.get(articleFamily.id)) {
        for (let i = 0; i < articleFamily.userTypes.length; i++) {
          if (articleFamily.userTypes[i].id === this.orderBag.order.user.userTypeId) {
            this.articleFamilyByTypeAndUserTypeList.push(articleFamily);
            break;
          }
        }
      }
    });
  }

  getArticle(orderArticle) {
    return this.articleMap.get(orderArticle.articleId);
  }

  onSelectArticle() {
    this.chooseArticle = true;
    this.orderArticleSelected = null;
    if (this.articleFamilyByTypeAndUserTypeList.length === 1) {
      this.onSelectArticleFamily(this.articleFamilyByTypeAndUserTypeList[0]);
    }
  }

  backToArticleList() {
    if (this.articleFamilyByTypeAndUserTypeList.length === 1 && this.articleByFamilyMap.get(this.articleFamilySelected.id).length === 1) {
      this.chooseArticle = false;
    }
    this.articleSelected = null;
    this.articleFamilySelected = null;
  }

  /**
   * Method called when user click on an article family on the left side
   * @param articleFamily articleFamily to set to articleFamilySelected variable
   */
  onSelectArticleFamily(articleFamily = null) {
    if (articleFamily === this.articleFamilySelected) {
      this.articleFamilySelected = null;
    } else {
      this.articleFamilySelected = articleFamily;
      if (this.articleByFamilyMap.get(this.articleFamilySelected.id).length === 1) {
        this.setArticleSelectedAndSetValidators(this.articleByFamilyMap.get(this.articleFamilySelected.id)[0]);
      } else {
        this.articleSelected = null;
      }
    }
  }

  setArticleSelectedAndSetValidators(article) {
    this.articleSelected = article;
    if (this.articleSelected.unity === ArticleUnity.KG) {
      this.invoiceForm.controls['quantity'].setValue(0.00);
      if (!this.orderBag.order.user.multipleArticle) {
        this.invoiceForm.controls['quantity'].setValidators([Validators.min(0.00), Validators.max(2.5), Validators.required]);
      } else {
        this.invoiceForm.controls['quantity'].setValidators([Validators.min(0.00), Validators.required]);
      }
      this.invoiceForm.controls['quantity'].updateValueAndValidity();
    } else {
      this.invoiceForm.controls['quantity'].setValidators([Validators.min(1), Validators.pattern('[0-9]*'), Validators.required]);
      this.invoiceForm.controls['quantity'].updateValueAndValidity();
    }
  }

  onValidateBag() {
    const confirmationModal = this.modalService.open(ConfirmationModalComponent, {backdrop: 'static', keyboard: false, centered: true});
    confirmationModal.componentInstance.confirmationDesc = 'Êtes-vous sûr de vouloir valider le sac ?';
    confirmationModal.componentInstance.validateButtonText = 'Valider';
    confirmationModal.componentInstance.cancelButtonText = 'Annuler';

    confirmationModal.result
      .then((result) => {
        if (result) {
          this.apiLavandierService.putOrderBagFinish(this.orderBag.id)
            .subscribe((data) => {
              if (!this.errorService.manageError(data)) {
                this.router.navigate(['/scan-bag']);
              }
            });
        }
      });
  }

  onAddArticle() {
    if (!this.orderBag.order.user.multipleArticle) {
      const webcamModalComponent = this.modalService.open(WebcamModalComponent, {
        size: 'lg',
        centered: true,
        backdrop: 'static',
        keyboard: false,
      });
      webcamModalComponent.componentInstance.confirmationDesc = 'PREVISUALISATION DE LA WEBCAM ET BOUTON PRISE DE PHOTO / RECOMMENCER PHOTO';
      webcamModalComponent.componentInstance.validateButtonText = 'VALIDER';
      webcamModalComponent.componentInstance.cancelButtonText = 'ANNULER';
      webcamModalComponent.result
        .then((file: File) => {
          if (file) {
            this.addArticle(file);
          }
        })
        .catch(() => null);
    } else {
      this.addArticle(null);
    }
  }

  addArticle(file: File) {
    // Fill article object
    const article = {
      quantity: this.invoiceForm.value.quantity,
      pinNumber: null,
      pinColor: null,
      article: this.articleSelected,
      articleFamily: this.articleFamilySelected,
      articleOptionList: [],
      refList: [],
    };
    if (this.articleSelected.unity === ArticleUnity.KG) {
      article.pinNumber = this.pinForm.value.pinNumber;
      article.pinColor = this.pinForm.value.pinColor;
    }
    // Generate ref code
    let i = 0;
    const refListAlreadyUsed = this.getRefIdListAlreadyUsed();
    while (i < this.articleSelected.nbPiece) {
      const availableRefId = this.getRefId(refListAlreadyUsed);
      refListAlreadyUsed.push(availableRefId);
      const refId = this.orderBagId.toString() + '-' + availableRefId;
      article.refList.push({
        code: refId,
        name: article.article.name,
      });
      i++;
    }
    // Fill articleOption list
    this.articleSelected.articleOptions.forEach((articleOption) => {
      if (this.invoiceForm.controls[articleOption.id].value) {
        article.articleOptionList.push(articleOption);
      }
    });
    // Create articleOptionId list
    const articleOptionIdList: number[] = [];
    article.articleOptionList.forEach(articleOption => articleOptionIdList.push(articleOption.id));
    // Create refCode list
    const refCodeList = [];
    article.refList.forEach((ref: any) => {
      refCodeList.push(ref.code);
    });

    this.apiLavandierService.postOrderBagOrderArticle(this.orderBagId, file, {
      articleId: article.article.id,
      articleOptionIdList: articleOptionIdList,
      refList: refCodeList,
      quantity: article.quantity * PRICEMULTIPLIER,
      pinColor: article.pinColor,
      pinNumber: article.pinNumber,
    })
      .subscribe(data => {
        if (!this.errorService.manageError(data)) {
          this.printArticleTagTracking({
            name: article.article.name,
            optionList: article.articleOptionList.map(articleOption => articleOption.name),
            refList: refCodeList
          })
            .subscribe(() => {
              this.articleFamilySelected = null;
              this.chooseArticle = false;
              this.loadOrderBagData()
                .subscribe(() => {
                  this.buildForm();
                });
            });
        }
      });
    this.articleSelected = null;
  }

  openDeleteArticleValidation(orderArticle) {
    const invoiceDeleteArticleModal = this.modalService.open(InvoiceDeleteArticleModalComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false
    });
    invoiceDeleteArticleModal.componentInstance.orderArticleToDelete = orderArticle;
    invoiceDeleteArticleModal.componentInstance.articleFamily = this.articleFamilyMap.get(this.getArticle(orderArticle).articleFamilyId);
    invoiceDeleteArticleModal.result
      .then(() => {
        this.apiLavandierService.deleteOrderBagOrderArticle(this.orderBag.id, orderArticle.id)
          .subscribe(data => {
            if (!this.errorService.manageError(data)) {
              this.articleSelected = null;
              this.articleFamilySelected = null;
              this.chooseArticle = false;
              this.loadOrderBagData().subscribe(() => {
                this.buildForm();
              });
            }
          });
      })
      .catch(() => this.orderArticleSelected = null);
  }

  getRefIdListAlreadyUsed(): number[] {
    const refIdListAlreadyUsed = [];

    this.orderBag.orderArticles.forEach(orderArticle => {
      orderArticle.orderArticleTrackingList.forEach(orderArticleTracking => {
        const refIdUsed = orderArticleTracking.ref.split('-')[orderArticleTracking.ref.split('-').length - 1];
        refIdListAlreadyUsed.push(Number(refIdUsed));
      });
    });

    return refIdListAlreadyUsed;
  }

  getRefId(refIdListAlreadyUsed: number[]): number {
    let refId = 0;
    let refIdFound = false;

    while (!refIdFound) {
      refIdFound = !refIdListAlreadyUsed.includes(refId);
      if (refIdFound) {
        return refId;
      }
      refId++;
    }
  }

  printArticleTagTracking(params: { name: string, optionList: string[], refList: string[] }): Observable<any> {
    const observableList: Observable<any>[] = [];

    params.refList.forEach((ref) => {
      observableList.push(this.apiLavandierService.getDocArticleTrackingType({
        orderId: this.orderBag.orderId,
        'refList[]': [ref],
        'nameList[]': [params.name],
        'optionList[]': [params.optionList.map(option => option.substr(0, 4)).join(' ')],
        type: 'png',
      }));
    });

    return this.printService.printObservableList(observableList);
  }

  /**
   * Method to add or remove a number in formControl
   * @param value New value to add or -1 if it's for removing
   * @param formControl FormControl to patch value
   * @param multiply For decimal
   */
  setPadValueToFormControl(value, formControl: FormControl, multiply) {
    if (value === -1) { // To remove the last number (159 -> 15)
      formControl.patchValue((formControl.value * multiply - ((formControl.value * multiply) % 10)) / (10 * multiply));
    } else { // To add a number at the end (15 -> 159)
      formControl.patchValue(((formControl.value * multiply * 10) + value).toFixed(0) / multiply);
    }
  }

  getArticleRefQuantity(): number {
    let refQuantity = 0;
    this.orderBag.orderArticles.forEach(orderArticle => refQuantity += orderArticle.orderArticleTrackingList.length);
    return refQuantity;
  }

  openReprintModal(orderArticle) {
    const reprintModal = this.modalService.open(ReprintArticleTrackingComponent, {centered: true});
    reprintModal.componentInstance.orderArticle = orderArticle;
    reprintModal.componentInstance.article = this.articleMap.get(orderArticle.articleId);
    reprintModal.result
      .then((articleRefList: any[]) => {
        return this.printArticleTagTracking({
          name: this.articleMap.get(orderArticle.articleId).name,
          optionList: orderArticle.orderArticleOptions.map(articleOption => articleOption.name),
          refList: articleRefList,
        })
          .subscribe(() => this.orderArticleSelected = null);
      })
      .catch(() => this.orderArticleSelected = null);
  }

  onStandBy() {
    this.modalService.open(StandByModalComponent, {centered: true})
      .result.then((result) => {
      if (result) {
        this.apiLavandierService.putOrderBagStandby(this.orderBag.id, {standby: result})
          .subscribe(data => {
            if (!this.errorService.manageError(data)) {
              this.router.navigate(['/scan-bag']);
            }
          });
      }
    })
      .catch(() => null);
  }

  onDefect() {
    const orderArticleTrackingDefectModal = this.modalService.open(OrderArticleTrackingDefectModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    orderArticleTrackingDefectModal.componentInstance.defectStation = OrderArticleTrackingDefectStation.SORT;
    orderArticleTrackingDefectModal.result
      .then(() => null)
      .catch(() => null);
  }
}
