import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ArticleFamilyType } from '../../../../lib/lib-shared/types/ArticleFamilyType';

@Component({
  selector: 'lm-invoice-delete-article-modal',
  templateUrl: './invoice-delete-article-modal.component.html',
  styleUrls: ['./invoice-delete-article-modal.component.scss']
})
export class InvoiceDeleteArticleModalComponent implements OnInit {
  public ArticleFamilyType = ArticleFamilyType;

  public orderArticleToDelete = null;
  public articleFamily = null;
  public invoiceItemCurrentScan = 0;
  public invoiceItemScanError = false;
  public invoiceItemRefIdInputFormControl = new FormControl('');
  @ViewChild('invoiceItemRefIdInput') invoiceItemRefIdInput: ElementRef;

  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit() {
    this.getInvoiceItemRefIdInputFocus();
    this.invoiceItemRefIdInputFormControl.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe(
        (ref) => {
          if (ref !== '') { // Check only if they have any content
            if (this.orderArticleToDelete.orderArticleTrackingList[this.invoiceItemCurrentScan].ref === ref) { // If refCode match
              this.invoiceItemCurrentScan++;
              if (this.invoiceItemCurrentScan === this.orderArticleToDelete.orderArticleTrackingList.length) { // If it's the last one
                this.activeModal.close(); // Close modal
              } else { // Otherwise reset value in input and reset focus
                this.invoiceItemRefIdInputFormControl.setValue('');
                this.invoiceItemScanError = false;
                this.getInvoiceItemRefIdInputFocus();
              }
            } else { // Otherwise show error and reset input
              this.invoiceItemRefIdInputFormControl.setValue('');
              this.invoiceItemScanError = true;
            }
          }
        }
      );
  }

  getInvoiceItemRefIdInputFocus() {
    setTimeout(() => this.invoiceItemRefIdInput.nativeElement.focus());
  }
}
