import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'lm-reprint-article-tracking',
  templateUrl: './reprint-article-tracking.component.html',
  styleUrls: ['./reprint-article-tracking.component.scss']
})
export class ReprintArticleTrackingComponent implements OnInit {
  public orderArticle = null;
  public article = null;

  public reprintForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.reprintForm = this.fb.group({});
    this.orderArticle.orderArticleTrackingList.forEach(orderArticleTracking => this.reprintForm.addControl(orderArticleTracking.ref, new FormControl(false)));
  }

  onReprint() {
    const articleRefToReprint = [];
    this.orderArticle.orderArticleTrackingList.forEach(orderArticleTracking => {
      if (this.reprintForm.controls[orderArticleTracking.ref].value) {
        articleRefToReprint.push(orderArticleTracking.ref);
      }
    });
    if (articleRefToReprint.length) {
      this.activeModal.close(articleRefToReprint);
    } else {
      this.activeModal.dismiss();
    }
  }
}
