import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

import { AuthService } from '../web-services/auth.service';
import { ApiService } from '../web-services/api.service';
import { ApiLavandierService } from '../web-services/api-lavandier.service';
import { HttpInterceptorVersionService } from './http-interceptor-version.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private config = null;

  public isBrowser = isPlatformBrowser(this.platformId);

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private injector: Injector,
  ) {
  }

  loadAppConfig() {
    if (this.isBrowser) {
      return this.injector.get(HttpClient).get('assets/json/config.json')
        .pipe(tap(config => {
          this.config = config;
          this.updateUrl();
        }))
        .toPromise();
    }
  }

  updateUrl() {
    if (this.isBrowser && this.config && this.config.hasOwnProperty('api_url') && this.config.api_url) {
      this.injector.get(AuthService).setUrl(this.config.api_url);
      this.injector.get(ApiService).setUrl(this.config.api_url);
      this.injector.get(ApiLavandierService).setUrl(this.config.api_url);

      this.injector.get(HttpInterceptorVersionService).setUrl(this.config.api_url);
    }
  }
}
