import { InjectionToken } from '@angular/core';
import { VersionService } from './version.service';
import { ConfigService } from './config.service';

export const APP_TOKEN = new InjectionToken('APP_TOKEN');

export const appInitializerFn = (appConfig: ConfigService, versionService: VersionService, app: { name: string, version: string }) => {
  return () => {
    versionService.setAppName(app.name);
    versionService.setAppVersion(app.version);
    return appConfig.loadAppConfig();
  };
};
