import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { VersionService } from './version.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorVersionService {

  private url = environment.api_url;

  constructor(
    private versionService: VersionService,
  ) {
  }

  setUrl(base: string) {
    this.url = base;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes(this.url)) {
      const authReq = req.clone({
        headers: req.headers
          .set('lm-app-name', this.versionService.getAppName() ? this.versionService.getAppName() : '')
          .set('lm-app-version', this.versionService.getAppVersion() ? this.versionService.getAppVersion() : '')
      });
      return next.handle(authReq);
    }

    return next.handle(req);
  }
}
