import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasFlags'
})
export class HasFlagsPipe implements PipeTransform {

  transform(flagsL: number, flagsR: number): boolean {
    return !!(flagsL & flagsR);
  }

}
