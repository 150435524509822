export enum Error {
  INCORRECT_VALUE,
  ORDER_NOT_QUARTERBACKABLE,
  PICKUP_ADDRESS_NOT_FOUND,
  ORDER_NOT_FOUND,
  QRCODE_NOT_VALID,
}

export const ErrorMap = new Map([
  [Error.INCORRECT_VALUE, ``],
  [Error.ORDER_NOT_QUARTERBACKABLE, `Commande non quarterbackable`],
  [Error.PICKUP_ADDRESS_NOT_FOUND, `Aucune de vos adresses ne correspond à celle de l'enlèvement`],
  [Error.ORDER_NOT_FOUND, `Commande non trouvée.`],
  [Error.QRCODE_NOT_VALID, `QRCode non valide`],
]);
