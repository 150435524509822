import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../../lib/lib-ngx/web-services/auth.service';
import { AlertService } from '../../../lib/lib-ngx/services/alert.service';

@Component({
  selector: 'lm-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  public isCollapsed = true;

  constructor(
    public authService: AuthService,
    public router: Router,
    public alertService: AlertService,
  ) {
  }

  ngOnInit() {
  }

  disconnected() {
    this.authService.logout();
    this.isCollapsed = true;
    this.router.navigate(['/login']);
  }

  onCloseAlert() {
    this.alertService.hide();
  }
}
