import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  private appName: string;
  private appVersion: string;

  constructor() {
  }

  setAppName(appName: string): void {
    this.appName = appName;
  }

  setAppVersion(appVersion: string): void {
    this.appVersion = appVersion;
  }

  getAppName(): string {
    return this.appName;
  }

  getAppVersion(): string {
    return this.appVersion;
  }
}
