import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  OrderArticleTrackingDefectModalComponent
} from '../modals/order-article-tracking-defect-modal/order-article-tracking-defect-modal.component';
import { OrderArticleTrackingDefectStation } from '../../../lib/lib-shared/types/OrderArticleTrackingDefectStation';

@Component({
  selector: 'lm-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public OrderArticleTrackingDefectStation = OrderArticleTrackingDefectStation;

  constructor(
    private modalService: NgbModal,
  ) {
  }

  ngOnInit() {
  }

  openDefaultOrderArticleModal(defectStation: OrderArticleTrackingDefectStation) {
    const orderArticleTrackingDefectModal = this.modalService.open(OrderArticleTrackingDefectModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    orderArticleTrackingDefectModal.componentInstance.defectStation = defectStation;
    orderArticleTrackingDefectModal.result
      .then(() => null)
      .catch(() => null);
  }
}
