import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { AuthService } from './auth.service';
import {
  IIncident,
  IIncidentComment,
  IIncidentImages, IIncidentNew, IIncidentStatus,
  IOrderEdit,
  IRole,
  IArticle,
  IArticleOption,
  IArticleOptionPrice,
  IArticleOptionType,
  IArticlePrice,
  IDiscountCode,
  IIncidentType,
  IOrderAdmin,
  IOrderSearch,
  IOrderTransport,
  IUpload, IUserEdit
} from './api-lavandier.service.type';
import { IAddressOrder, IOrderBag } from './api.service.type';
import { StringPage } from '../utils/Page';
import { OrderTransportType } from '../../lib-shared/types/OrderTransportType';
import { CreditType } from '../../lib-shared/types/CreditType';
import { ShopStyle } from '../../lib-shared/types/ShopStyle';
import { PaymentType } from '../../lib-shared/types/PaymentType';
import { ConfigInvoiceTemplate } from '../../lib-shared/types/ConfigInvoiceTemplate';
import { EventConfigType } from '../../lib-shared/types/EventConfigType';
import { EventConfigEvent } from '../../lib-shared/types/EventConfigEvent';
import { StorageType } from '../../lib-shared/types/StorageType';
import { ShopLogo } from '../../lib-shared/types/ShopLogo';
import { ShopEndpoint } from '../../lib-shared/types/ShopEndpoint';
import { OrderArticleTrackingDefectOrigin } from '../../lib-shared/types/OrderArticleTrackingDefectOrigin';
import { OrderArticleTrackingDefectStation } from '../../lib-shared/types/OrderArticleTrackingDefectStation';
import { OrderArticleTrackingDefectType } from '../../lib-shared/types/OrderArticleTrackingDefectType';
import { TransportScheduleType } from '../../lib-shared/types/TransportScheduleType';
import { ArticleFamilyType } from '../../lib-shared/types/ArticleFamilyType';
import { LightState } from '../../lib-shared/types/LightState';
import { AddressField, getAddressField } from '../utils/AddressField';
import {
  TTransportSheetGetRefDetailsRep,
  ITransportSheetGetRefDetailsReq,
  TTransportSheetPutIdFactoryReceivedRep,
  TTransportSheetPutIdFactoryReceivedReq, ITransportSheetGetIdRePrintReq, TTransportSheetGetIdRePrintRep
} from '../../lib-shared/api/lavandier/TransportSheet.Type';
import {
  TOrderBagGetTransportSheetRefDetailsRep,
  IOrderBagGetTransportSheetRefDetailsReq
} from '../../lib-shared/api/lavandier/OrderBag.Type';
import {
  IOrderGetKitBatchReq, IOrderGetKitReq,
  IOrderPutIdKitBatchReq, IOrderPutIdKitReq,
  TOrderGetKitBatchRep, TOrderGetKitRep,
  TOrderPutIdKitBatchRep, TOrderPutIdKitRep
} from '../../lib-shared/api/lavandier/Order.Type';
import { ShippingPriceType } from '../../lib-shared/types/ShippingPriceType';

@Injectable({
  providedIn: 'root'
})
export class ApiLavandierService {
  private url = ApiLavandierService.generateUrl(environment.api_url);

  constructor(
    private authService: AuthService,
    private http: HttpClient
  ) {
  }

  static generateUrl(base: string) {
    return `${base}/lavandier`;
  }

  setUrl(base: string) {
    this.url = ApiLavandierService.generateUrl(base);
  }

  postAccount(params: { userTypeId: number, name: string, address: { firstName, lastName, streetNumber, route, locality, country, postalCode, formattedAddress, lat, lng, phoneNumber, additional } }) {
    return this.http.post(`${this.url}/account`, params, this.authService.getHttpOptions());
  }

  putAccount(id: number, params: { name: string, address: { firstName, lastName, streetNumber, route, locality, country, postalCode, formattedAddress, lat, lng, phoneNumber, additional } }) {
    return this.http.put(`${this.url}/account/${id}`, params, this.authService.getHttpOptions());
  }

  getAccountDetails(id: number) {
    return this.http.get(`${this.url}/account/${id}/details`, this.authService.getHttpOptions());
  }

  postAddress(params: { userId, firstName, lastName, company?, streetNumber, route, locality, country, postalCode, formattedAddress, lat, lng, phoneNumber, additional }) {
    return this.http.post(`${this.url}/address`, params, this.authService.getHttpOptions());
  }

  putIdDefaultAddress(addressId: number, params: { userId: number }) {
    return this.http.put(`${this.url}/address/${addressId}/default`, params, this.authService.getHttpOptions());
  }

  putAddress(addressId: number, params: {
    firstName, lastName, company?, streetNumber, route, locality, country, postalCode, formattedAddress, lat, lng, phoneNumber, additional
  }) {
    return this.http.put(`${this.url}/address/${addressId}`, params, this.authService.getHttpOptions());
  }

  deleteAddress(addressId: number) {
    return this.http.delete(`${this.url}/address/${addressId}`, this.authService.getHttpOptions());
  }

  postArticleFamily(params: { userTypeIdList: number[], weight: number, type: ArticleFamilyType, name: string, color: string }) {
    return this.http.post(`${this.url}/article/family`, params, this.authService.getHttpOptions());
  }

  postArticleOptionPrice(articleOptionPrice: IArticleOptionPrice) {
    return this.http.post(`${this.url}/article/option/price`, articleOptionPrice, this.authService.getHttpOptions());
  }

  postArticleOptionType(articleOptionType: IArticleOptionType) {
    return this.http.post(`${this.url}/article/option/type`, articleOptionType, this.authService.getHttpOptions());
  }

  postArticleOption(articleOption: IArticleOption) {
    return this.http.post(`${this.url}/article/option`, articleOption, this.authService.getHttpOptions());
  }

  postArticlePrice(articlePrice: IArticlePrice) {
    return this.http.post(`${this.url}/article/price`, articlePrice, this.authService.getHttpOptions());
  }

  postArticle(article: IArticle) {
    return this.http.post(`${this.url}/article`, article, this.authService.getHttpOptions());
  }

  putArticleFamily(articleFamilyId: number, params: { userTypeIdList: number[], weight: number, type: ArticleFamilyType, name: string, color: string }) {
    return this.http.put(`${this.url}/article/family/${articleFamilyId}`, params, this.authService.getHttpOptions());
  }

  putArticleOptionPrice(articleOptionPriceId: number, articleOptionPrice: IArticleOptionPrice) {
    return this.http.put(`${this.url}/article/option/price/${articleOptionPriceId}`, articleOptionPrice, this.authService.getHttpOptions());
  }

  putArticleOptionType(articleOptionTypeId: number, articleOptionType: IArticleOptionType) {
    return this.http.put(`${this.url}/article/option/type/${articleOptionTypeId}`, articleOptionType, this.authService.getHttpOptions());
  }

  putArticleOption(articleOptionId: number, articleOption: IArticleOption) {
    return this.http.put(`${this.url}/article/option/${articleOptionId}`, articleOption, this.authService.getHttpOptions());
  }

  putArticlePrice(articlePriceId: number, articlePrice: IArticlePrice) {
    return this.http.put(`${this.url}/article/price/${articlePriceId}`, articlePrice, this.authService.getHttpOptions());
  }

  putArticle(articleId: number, article: IArticle) {
    return this.http.put(`${this.url}/article/${articleId}`, article, this.authService.getHttpOptions());
  }

  getArticleFamilyList() {
    return this.http.get(`${this.url}/article/family`, this.authService.getHttpOptions());
  }

  getArticleOptionPublicList() {
    return this.http.get(`${this.url}/article/option/public`, this.authService.getHttpOptions());
  }

  getArticleOptionTypeList() {
    return this.http.get(`${this.url}/article/option/type`, this.authService.getHttpOptions());
  }

  getArticleOptionList() {
    return this.http.get(`${this.url}/article/option`, this.authService.getHttpOptions());
  }

  getArticle(articleId) {
    return this.http.get(`${this.url}/article/${articleId}`, this.authService.getHttpOptions());
  }

  getArticleList() {
    return this.http.get(`${this.url}/article`, this.authService.getHttpOptions());
  }

  deleteArticleOptionPrice(articleOptionPriceId: number) {
    return this.http.delete(`${this.url}/article/option/price/${articleOptionPriceId}`, this.authService.getHttpOptions());
  }

  deleteArticlePrice(articlePriceId: number) {
    return this.http.delete(`${this.url}/article/price/${articlePriceId}`, this.authService.getHttpOptions());
  }

  postCalendarDay(params: { type, date }) {
    return this.http.post(`${this.url}/calendarday`, params, this.authService.getHttpOptions());
  }

  putCalendarDay(id, params: { type, date }) {
    return this.http.put(`${this.url}/calendarday/${id}`, params, this.authService.getHttpOptions());
  }

  getCalendarDayList() {
    return this.http.get(`${this.url}/calendarday`, this.authService.getHttpOptions());
  }

  postCredit(params: { userId: number, incidentId: number, type: CreditType, description: string, amount: number, endedAt: string }) {
    return this.http.post(`${this.url}/credit`, params, this.authService.getHttpOptions());
  }

  getCreditUser(userId: number) {
    return this.http.get(`${this.url}/credit/user/${userId}`, this.authService.getHttpOptions());
  }

  postDiscountCode(discountCode: IDiscountCode) {
    return this.http.post(`${this.url}/discountCode`, discountCode, this.authService.getHttpOptions());
  }

  getDiscountCodeIsValid(params: { userId: string, code: string }) {
    return this.http.get(`${this.url}/discountCode/isvalid`, {
      headers: this.authService.getHttpHeaders(), params: new HttpParams({fromObject: params})
    });
  }

  getDiscountCode(page: StringPage) {
    return this.http.get(`${this.url}/discountCode`, {
      headers: this.authService.getHttpHeaders(), params: {...page}
    });
  }

  getDocArticleTrackingType(params: { orderId: string, 'refList[]': string[], 'nameList[]': string[], 'optionList[]': string[], type: 'png' | 'pdf' }) {
    return this.http.get(`${this.url}/doc/articletracking/${params.type}`, {
      headers: this.authService.getHttpHeaders(), responseType: 'blob',
      params: new HttpParams({fromObject: params})
    });
  }

  getDocTrackingSheetType(id: number, params: { type: 'png' | 'pdf' }) {
    return this.http.get(`${this.url}/doc/trackingsheet/${id}/${params.type}`, {
      headers: this.authService.getHttpHeaders(), responseType: 'blob'
    });
  }

  getDocQRCodeTokenType(id: number, params: { type: 'png' | 'pdf' }) {
    return this.http.get(`${this.url}/doc/qrcodetoken/${id}/${params.type}`, {
      headers: this.authService.getHttpHeaders(), responseType: 'blob'
    });
  }

  postEventConfig(params: { configId: number, type: EventConfigType, event: EventConfigEvent, value: any }) {
    return this.http.post(`${this.url}/eventconfig`, params, this.authService.getHttpOptions());
  }

  putEventConfig(eventConfigId: number, params: { type: EventConfigType, event: EventConfigEvent, value: any }) {
    return this.http.put(`${this.url}/eventconfig/${eventConfigId}`, params, this.authService.getHttpOptions());
  }

  deleteEventConfig(eventConfigId: number) {
    return this.http.delete(`${this.url}/eventconfig/${eventConfigId}`, this.authService.getHttpOptions());
  }

  postIncidentIdOrderline(orderId, orderLine: { description: string, price: number, vat: number }) {
    return this.http.post(`${this.url}/incident/${orderId}/orderline`, orderLine, this.authService.getHttpOptions());
  }

  postIncidentComment(incidentComment: IIncidentComment) {
    return this.http.post(`${this.url}/incident/comment`, incidentComment, this.authService.getHttpOptions());
  }

  postIncidentImages(incidentImages: IIncidentImages) {
    const incidentImagesFormData = new FormData();
    incidentImagesFormData.append('incidentCommentId', incidentImages.incidentCommentId.toString());
    incidentImages.imageList.forEach((image, index) => {
      incidentImagesFormData.append('image' + index, image);
    });
    return this.http.post(`${this.url}/incident/images`, incidentImagesFormData, this.authService.getHttpOptions());
  }

  postIncidentStatus(incidentStatus: IIncidentStatus) {
    return this.http.post(`${this.url}/incident/status`, incidentStatus, this.authService.getHttpOptions());
  }

  postIncidentType(incidentType: IIncidentType) {
    return this.http.post(`${this.url}/incident/type`, incidentType, this.authService.getHttpOptions());
  }

  postIncident(incident: IIncidentNew) {
    return this.http.post(`${this.url}/incident`, incident, this.authService.getHttpOptions());
  }

  putIncidentType(incidentTypeId, incidentType: IIncidentType) {
    return this.http.put(`${this.url}/incident/type/${incidentTypeId}`, incidentType, this.authService.getHttpOptions());
  }

  putIncident(incidentId, incident: IIncident) {
    return this.http.put(`${this.url}/incident/${incidentId}`, incident, this.authService.getHttpOptions());
  }

  getIncidentDetails(incidentId) {
    return this.http.get(`${this.url}/incident/details/${incidentId}`, this.authService.getHttpOptions());
  }

  getIncidentImage(incidentImageId) {
    const httpOptions = this.authService.getHttpOptions();
    httpOptions['responseType'] = 'blob';
    return this.http.get(`${this.url}/incident/image/${incidentImageId}`, httpOptions);
  }

  getIncidentMine(page?: StringPage) {
    return this.http.get(`${this.url}/incident/mine`, {
      headers: this.authService.getHttpHeaders(), params: {...page},
    });
  }

  getIncidentTypes(params?: { public?: string, old?: string }) {
    return this.http.get(`${this.url}/incident/type`, {
      headers: this.authService.getHttpHeaders(),
      params: params,
    });
  }

  getIncidentList(params?: { id?: string, orderId?: string, assignLavandierId?: string, typeId?: string, userId?: string, finish?: string, origin?: string, name?: string, severity?: string }, page?: StringPage) {
    return this.http.get(`${this.url}/incident`, {
      headers: this.authService.getHttpHeaders(), params: {...params, ...page},
    });
  }

  postLavandierQRCode(id: number) {
    return this.http.post(`${this.url}/lavandier/${id}/qrcode`, null, this.authService.getHttpOptions());
  }

  postLavandier(lavandier: { userId: number, shopId: number, admin: boolean, internalAdmin: boolean }) {
    return this.http.post(`${this.url}/lavandier`, lavandier, this.authService.getHttpOptions());
  }

  putLavandierQRCodeDisable(id: number) {
    return this.http.put(`${this.url}/lavandier/${id}/qrcode/disable`, null, this.authService.getHttpOptions());
  }

  putLavandier(lavandierId: number, params: { shopId?: number, enabled?: boolean, roleIdList?: number[], admin?: boolean, internalAdmin?: boolean }) {
    return this.http.put(`${this.url}/lavandier/${lavandierId}`, params, this.authService.getHttpOptions());
  }

  getLavandierIdDetails(lavandierId) {
    return this.http.get(`${this.url}/lavandier/${lavandierId}/details`, this.authService.getHttpOptions());
  }

  getLavandiers(params?: { shopId?: string, enabled?: string, admin?: string, internalAdmin?: string, name?: string }, page?: StringPage) {
    return this.http.get(`${this.url}/lavandier`, {
      headers: this.authService.getHttpHeaders(), params: {...params, ...page},
    });
  }

  putLight(lightId, lightState: { state: LightState }) {
    return this.http.put(`${this.url}/light/${lightId}`, lightState, this.authService.getHttpOptions());
  }

  getLightDetailsList() {
    return this.http.get(`${this.url}/light/details`, this.authService.getHttpOptions());
  }

  getLightBridgeList() {
    return this.http.get(`${this.url}/light/bridge`, this.authService.getHttpOptions());
  }

  getLightList(params: {}) {
    return this.http.get(`${this.url}/light`, {
      headers: this.authService.getHttpHeaders(), params: new HttpParams({fromObject: params})
    });
  }

  postNotification(params: { userId: number, messageSMS: string, messageEmail: string }) {
    return this.http.post(`${this.url}/notification`, params, this.authService.getHttpOptions());
  }

  putOrderArticleTrackingRefStorage(params: { incidentSeen: boolean, ref: string }) {
    return this.http.put(`${this.url}/orderarticletracking/ref/storage`, params, this.authService.getHttpOptions());
  }

  putTrackingSheetShopStorage(id: number, params: { tsMissing }) {
    return this.http.put(`${this.url}/orderarticletracking/${id}/shopstorage`, params, this.authService.getHttpOptions());
  }

  putOrderArticleTrackingIdUnTidied(id: number) {
    return this.http.put(`${this.url}/orderarticletracking/${id}/untidied`, null, this.authService.getHttpOptions());
  }

  putOrderArticleTrackingIdFactory(id: number, params: { factoryMissing }) {
    return this.http.put(`${this.url}/orderarticletracking/${id}/factory`, params, this.authService.getHttpOptions());
  }

  putOrderArticleTrackingIdDefect(orderArticleTrackingId: number, file: File, params: { parentId?: number, origin: OrderArticleTrackingDefectOrigin, station: OrderArticleTrackingDefectStation, type: OrderArticleTrackingDefectType }) {
    const uploadFormData = new FormData();
    uploadFormData.append('data', JSON.stringify(params));
    uploadFormData.append('file', file);
    return this.http.put(`${this.url}/orderarticletracking/${orderArticleTrackingId}/defect`, uploadFormData, this.authService.getHttpOptions());
  }

  putOrderArticleTrackingIdTidied(orderArticleTrackingId, params: { forceFreeStorage: boolean }) {
    return this.http.put(`${this.url}/orderarticletracking/${orderArticleTrackingId}/tidied`, params, this.authService.getHttpOptions());
  }

  getOrderArticleTrackingRefDetails(ref: string) {
    return this.http.get(`${this.url}/orderarticletracking/ref/${ref}/details`, this.authService.getHttpOptions());
  }

  postOrderBagOrderArticle(orderBagId: number, file: File, params: { articleId: number, articleOptionIdList: number[], refList: string[], quantity: number, pinColor: string, pinNumber: number }) {
    const uploadFormData = new FormData();
    uploadFormData.append('data', JSON.stringify(params));
    uploadFormData.append('file', file);
    return this.http.post(`${this.url}/orderbag/${orderBagId}/orderarticle`, uploadFormData, this.authService.getHttpOptions());
  }

  putOrderBagStandby(id, params: { standby: string }) {
    return this.http.put(`${this.url}/orderbag/${id}/standby`, params, this.authService.getHttpOptions());
  }

  putOrderBagFinish(id) {
    return this.http.put(`${this.url}/orderbag/${id}/finish`, null, this.authService.getHttpOptions());
  }

  putOrderBagCheck(id) {
    return this.http.put(`${this.url}/orderbag/${id}/check`, null, this.authService.getHttpOptions());
  }

  putOrderBag(id, orderBag: IOrderBag) {
    return this.http.put(`${this.url}/orderbag/${id}`, orderBag, this.authService.getHttpOptions());
  }

  getOrderBagTransportSheetRefDetails(params: IOrderBagGetTransportSheetRefDetailsReq) {
    return this.http.get<TOrderBagGetTransportSheetRefDetailsRep>(`${this.url}/orderbag/transportsheet/ref/details`, {
      headers: this.authService.getHttpHeaders(),
      // as any is here for retro compatibility with old version of angular
      params: new HttpParams({fromObject: params as any}),
    });
  }

  getOrderBagRefDetails(ref) {
    return this.http.get(`${this.url}/orderbag/ref/${ref}/details`, this.authService.getHttpOptions());
  }

  getOrderBagDetails(id: number) {
    return this.http.get(`${this.url}/orderbag/${id}/details`, this.authService.getHttpOptions());
  }

  deleteOrderBagOrderArticle(id, orderArticleId) {
    return this.http.delete(`${this.url}/orderbag/${id}/orderarticle/${orderArticleId}`, this.authService.getHttpOptions());
  }

  postOrderIdOrderWantedDelivery(orderId, data: { addNoShowOrderLine: boolean, wantedDeliveryDate }) {
    return this.http.post(`${this.url}/order/${orderId}/orderwanteddelivery`, data, this.authService.getHttpOptions());
  }

  postOrderIdDelivery(id, params: { address: IAddressOrder, shopId?: number, date: string, start: string, end: string } | { inShopId: number }) {
    return this.http.post(`${this.url}/order/${id}/delivery`, params, this.authService.getHttpOptions());
  }

  postOrderIdPay(id, params: { paymentType: PaymentType, amount: number, externalId: string }) {
    return this.http.post(`${this.url}/order/${id}/pay`, params, this.authService.getHttpOptions());
  }

  postOrderKitBatch(params: { kitList: { kitId: number, quantity: number }[] }) {
    return this.http.post(`${this.url}/order/kitbatch`, params, this.authService.getHttpOptions());
  }

  postOrderSearch(orderSearch: IOrderSearch) {
    return this.http.post(`${this.url}/order/search`, orderSearch, this.authService.getHttpOptions());
  }

  postOrder(order: IOrderAdmin) {
    return this.http.post(`${this.url}/order`, order, this.authService.getHttpOptions());
  }

  putOrderIdTransportSheet(orderId: number) {
    return this.http.put(`${this.url}/order/${orderId}/transportsheet`, null, this.authService.getHttpOptions());
  }

  putOrderIdKitBatch(orderId: number, params: IOrderPutIdKitBatchReq) {
    return this.http.put<TOrderPutIdKitBatchRep>(`${this.url}/order/${orderId}/kitbatch`, params, this.authService.getHttpOptions());
  }

  putOrderIdKit(orderId: number, params: IOrderPutIdKitReq) {
    return this.http.put<TOrderPutIdKitRep>(`${this.url}/order/${orderId}/kit`, params, this.authService.getHttpOptions());
  }

  putOrderIdTransfer(orderId: number) {
    return this.http.put(`${this.url}/order/${orderId}/transfer`, null, this.authService.getHttpOptions());
  }

  putOrderCancel(orderId: number) {
    return this.http.put(`${this.url}/order/${orderId}/cancel`, null, this.authService.getHttpOptions());
  }

  putOrderIdFinish(id) {
    return this.http.put(`${this.url}/order/${id}/finish`, null, this.authService.getHttpOptions());
  }

  getOrderIdOrderArticleTrackingList(orderId: number) {
    return this.http.get(`${this.url}/order/${orderId}/orderarticletracking`, this.authService.getHttpOptions());
  }

  getOrderIdDetails(orderId) {
    return this.http.get(`${this.url}/order/${orderId}/details`, this.authService.getHttpOptions());
  }

  getOrderOrderArticleTrackingRefRefDetails(ref: string) {
    return this.http.get(`${this.url}/order/orderarticletracking/ref/${ref}/details`, this.authService.getHttpOptions());
  }

  getOrderReadyToShip() {
    return this.http.get(`${this.url}/order/readytoship`, this.authService.getHttpOptions());
  }

  getOrderKitBatch(params: IOrderGetKitBatchReq) {
    return this.http.get<TOrderGetKitBatchRep>(`${this.url}/order/kitbatch`, this.authService.getHttpOptions());
  }

  getOrderKit(params: IOrderGetKitReq) {
    return this.http.get<TOrderGetKitRep>(`${this.url}/order/kit`, this.authService.getHttpOptions());
  }

  getOrderUserIdList(userId, params?: { temporary?: string, createdAt?: string, estimatedDeliveryAt?: string, orderId?: string, paid?: string }, page?: StringPage) {
    return this.http.get(`${this.url}/order/user/${userId}`, {
      headers: this.authService.getHttpHeaders(), params: {...params, ...page},
    });
  }

  getOrderByPin(pin: string) {
    return this.http.get(`${this.url}/order/pin/${pin}`, this.authService.getHttpOptions());
  }

  getOrderList(params?: { id?: string, userTypeId?: string, temporary?: string, paid?: string, name?: string, createdAt?: string, estimatedDeliveryAt?: string, pickupShopId?: string }, page?: StringPage) {
    return this.http.get(`${this.url}/order`, {
      headers: this.authService.getHttpHeaders(), params: {...params, ...page},
    });
  }

  postOrderTransport(orderTransport: IOrderTransport) {
    return this.http.post(`${this.url}/ordertransport`, orderTransport, this.authService.getHttpOptions());
  }

  putOrderTransportReOpen(orderTransportId: number) {
    return this.http.put(`${this.url}/ordertransport/${orderTransportId}/reopen`, null, this.authService.getHttpOptions());
  }

  getOrderTransportDetails(orderTransportId: number) {
    return this.http.get(`${this.url}/ordertransport/${orderTransportId}/details`, this.authService.getHttpOptions());
  }

  getOrderTransportList(params?: { canceled?: string, name?: string, simultaneous?: string, shopId?: string, type?: OrderTransportType, inShop?: string, date?: string }) {
    return this.http.get(`${this.url}/ordertransport`, {
      headers: this.authService.getHttpHeaders(), params: params,
    });
  }

  getPdfInvoice(orderId: number) {
    return this.http.get(`${this.url}/pdf/invoice/${orderId}`, {
      headers: this.authService.getHttpHeaders(), responseType: 'blob'
    });
  }

  postRole(role: IRole) {
    return this.http.post(`${this.url}/role`, role, this.authService.getHttpOptions());
  }

  putRole(id: number, role: IRole) {
    return this.http.put(`${this.url}/role/${id}`, role, this.authService.getHttpOptions());
  }

  getRole(id: number) {
    return this.http.get(`${this.url}/role/${id}`, this.authService.getHttpOptions());
  }

  getRoleList() {
    return this.http.get(`${this.url}/role`, this.authService.getHttpOptions());
  }

  postShippingPrice(params: { userTypeId: number, type: ShippingPriceType, diff: boolean, start: number, end: number, price: number, orderException: number }) {
    return this.http.post(`${this.url}/shippingprice`, params, this.authService.getHttpOptions());
  }

  putShippingPrice(shippingPriceId: number, params: { type: ShippingPriceType, diff: boolean, start: number, end: number, price: number, orderException: number }) {
    return this.http.put(`${this.url}/shippingprice/${shippingPriceId}`, params, this.authService.getHttpOptions());
  }

  deleteShippingPrice(shippingPriceId: number) {
    return this.http.delete(`${this.url}/shippingprice/${shippingPriceId}`, this.authService.getHttpOptions());
  }

  postShopPerimeter(shopId: number, address: google.maps.places.PlaceResult, timeLimit: number) {
    const data = {
      postalCode: getAddressField(address, AddressField.POSTALCODE),
      city: getAddressField(address, AddressField.LOCALITY),
      timeLimit: timeLimit,
      shopId: shopId
    };
    return this.http.post(`${this.url}/shop/perimeter`, data, this.authService.getHttpOptions());
  }

  postShop(style: ShopStyle, logo: ShopLogo, endpoint: ShopEndpoint, ref: string, name: string, color: string, phoneNumber: number, address: google.maps.places.PlaceResult) {
    const data = {
      style: style,
      logo: logo,
      endpoint: endpoint,
      ref: ref,
      name: name,
      color: color,
      streetNumber: getAddressField(address, AddressField.STREETNUMBER),
      route: getAddressField(address, AddressField.ROUTE),
      locality: getAddressField(address, AddressField.LOCALITY),
      country: getAddressField(address, AddressField.COUNTRY),
      postalCode: getAddressField(address, AddressField.POSTALCODE),
      formattedAddress: address.formatted_address,
      lat: address.geometry.location.lat(),
      lng: address.geometry.location.lng(),
      phoneNumber: phoneNumber,
      additional: '',
    };
    return this.http.post(`${this.url}/shop`, data, this.authService.getHttpOptions());
  }

  putShopHours(shopId: number, params: { hours: { name: string, value: string }[] }) {
    return this.http.put(`${this.url}/shop/${shopId}/hours`, params, this.authService.getHttpOptions());
  }

  getShop(shopId: number) {
    return this.http.get(`${this.url}/shop/${shopId}`, this.authService.getHttpOptions());
  }

  getShops() {
    return this.http.get(`${this.url}/shop`, this.authService.getHttpOptions());
  }

  deleteShopPerimeter(perimeterId) {
    return this.http.delete(`${this.url}/shop/perimeter/${perimeterId}`, this.authService.getHttpOptions());
  }

  getShopStorageByRef(params: { ref: string }) {
    return this.http.get(`${this.url}/shopstorage/ref`, {
      headers: this.authService.getHttpHeaders(), params: new HttpParams({fromObject: params})
    });
  }

  getSponsorshipIsValid(params: { userId: string, code: string }) {
    return this.http.get(`${this.url}/sponsorship/isvalid`, {
      headers: this.authService.getHttpHeaders(), params: new HttpParams({fromObject: params})
    });
  }

  getStorageUsedList() {
    return this.http.get(`${this.url}/storage/used`, this.authService.getHttpOptions());
  }

  getStorageList() {
    return this.http.get(`${this.url}/storage`, this.authService.getHttpOptions());
  }

  postTimeSlot(shopIds: Object[], typeId, days: Object[], start, timeLimit, newSlot, oldSlot) {
    const data = {
      timeSlots: []
    };

    shopIds.forEach(function (shopId) {
      days.forEach(function (day) {
        data.timeSlots.push({
          shopId: shopId,
          typeId: typeId,
          day: day,
          start: start,
          timeLimit: timeLimit,
          newSlot: newSlot,
          oldSlot: oldSlot,
        });
      });
    });
    return this.http.post(`${this.url}/timeslot`, data, this.authService.getHttpOptions());
  }

  putTimeSlotType(slotTypeId: number, color: string, duration: number, switchDate) {
    const data = {
      color: color,
      duration: duration,
      switchDate: switchDate
    };
    return this.http.put(`${this.url}/timeslot/type/${slotTypeId}`, data, this.authService.getHttpOptions());
  }

  putTimeSlot(id, params: { shopId: number, typeId: number, day: number, start: string, timeLimit: number, newSlot: number, oldSlot: number }) {
    return this.http.put(`${this.url}/timeslot/${id}`, params, this.authService.getHttpOptions());
  }

  getTimeSlotTypes() {
    return this.http.get(`${this.url}/timeslot/type`, this.authService.getHttpOptions());
  }

  deleteTimeSlot(timeSlotId) {
    return this.http.delete(`${this.url}/timeslot/${timeSlotId}`, this.authService.getHttpOptions());
  }

  putTrackingSheetPTLTook(id: number) {
    return this.http.put(`${this.url}/trackingsheet/${id}/ptltook`, null, this.authService.getHttpOptions());
  }

  getTrackingSheetDetails(id: number) {
    return this.http.get(`${this.url}/trackingsheet/details/${id}`, this.authService.getHttpOptions());
  }

  getTrackingSheetDetailsList() {
    return this.http.get(`${this.url}/trackingsheet/details`, this.authService.getHttpOptions());
  }

  getTrackingSheetOrderId(id: number) {
    return this.http.get(`${this.url}/trackingsheet/order/${id}`, this.authService.getHttpOptions());
  }

  getTrackingSheet(id: number) {
    return this.http.get(`${this.url}/trackingsheet/${id}`, this.authService.getHttpOptions());
  }

  postTrackingSheetStorageTidied(params: { trackingSheetId: number }) {
    return this.http.post(`${this.url}/trackingsheetstorage/tidied`, params, this.authService.getHttpOptions());
  }

  putTrackingSheetStorageIdPrepared(id: number, params: { missing: boolean }) {
    return this.http.put(`${this.url}/trackingsheetstorage/${id}/prepared`, params, this.authService.getHttpOptions());
  }

  putTrackingSheetStorageIdStorage(id: number, params: { shopStorageId: number }) {
    return this.http.put(`${this.url}/trackingsheetstorage/${id}/storage`, params, this.authService.getHttpOptions());
  }

  postTransportSchedule(params: { shopId: number, userId: number, addressId: number, type: TransportScheduleType, checkDay: number, checkTime: string, day: number, start: string, nbDayToAdd: number, duration: number }) {
    return this.http.post(`${this.url}/transportschedule`, params, this.authService.getHttpOptions());
  }

  putTransportSchedule(transportScheduleId: number, params: { shopId: number, addressId: number, type: TransportScheduleType, checkDay: number, checkTime: string, day: number, start: string, nbDayToAdd: number, duration: number }) {
    return this.http.put(`${this.url}/transportschedule/${transportScheduleId}`, params, this.authService.getHttpOptions());
  }

  deleteTransportSchedule(transportScheduleId: number) {
    return this.http.delete(`${this.url}/transportschedule/${transportScheduleId}`, this.authService.getHttpOptions());
  }

  putTransportSheetIdFactoryReceived(id: number, params: TTransportSheetPutIdFactoryReceivedReq) {
    return this.http.put<TTransportSheetPutIdFactoryReceivedRep>(`${this.url}/transportsheet/${id}/factoryreceived`, null, this.authService.getHttpOptions());
  }

  getTransportSheetRefDetails(params: ITransportSheetGetRefDetailsReq) {
    return this.http.get<TTransportSheetGetRefDetailsRep>(`${this.url}/transportsheet/ref/details`, {
      headers: this.authService.getHttpHeaders(),
      // as any is here for retro compatibility with old version of angular
      params: new HttpParams({fromObject: params as any}),
    });
  }

  getTransportSheetIdRePrint(id: number, params: ITransportSheetGetIdRePrintReq) {
    return this.http.get<TTransportSheetGetIdRePrintRep>(`${this.url}/transportsheet/${id}/reprint`, this.authService.getHttpOptions());
  }

  postUpload(upload: IUpload) {
    const uploadFormData = new FormData();
    uploadFormData.append('folder', upload.folder.toString());
    uploadFormData.append('file', upload.file);
    return this.http.post(`${this.url}/upload`, uploadFormData, this.authService.getHttpOptions());
  }

  getUpload(uploadId) {
    const httpOptions = this.authService.getHttpOptions();
    httpOptions['responseType'] = 'blob';
    return this.http.get(`${this.url}/upload/${uploadId}`, httpOptions);
  }

  postUserType(userType: { parentUserTypeId: number, name: string, sponsorship: boolean, important: number, noShowException: number, noShowPrice: number, storageException: number, storagePrice: number, storageDeadLine: number, storageDeadLineEvent1: number, storageDeadLineEvent2: number, sponsorshipGodfatherDiscount: number, sponsorshipGodsonDiscount: number, sponsorshipMinOrder: number, configInvoiceTemplate: ConfigInvoiceTemplate }) {
    return this.http.post(`${this.url}/user/type`, userType, this.authService.getHttpOptions());
  }

  postUser(params: { userTypeId, accountId?, dataConsent, multipleArticle: boolean, orderName: boolean, email?, password?, firstName, lastName, phoneNumber?, address? }) {
    return this.http.post(`${this.url}/user`, params, this.authService.getHttpOptions());
  }

  putUserType(userTypeId: number, userType: { parentUserTypeId: number, name: string, sponsorship: boolean, fakePayment: boolean, important: number, noShowException: number, noShowPrice: number, storageException: number, storagePrice: number, storageDeadLine: number, storageDeadLineEvent1: number, storageDeadLineEvent2: number, sponsorshipGodfatherDiscount: number, sponsorshipGodsonDiscount: number, sponsorshipMinOrder: number }) {
    return this.http.put(`${this.url}/user/type/${userTypeId}`, userType, this.authService.getHttpOptions());
  }

  putUserIdEnabled(userId: number, data: { enabled: boolean }) {
    return this.http.put(`${this.url}/user/${userId}/enabled`, data, this.authService.getHttpOptions());
  }

  putUser(userId: number, userEdit: IUserEdit) {
    return this.http.put(`${this.url}/user/${userId}`, userEdit, this.authService.getHttpOptions());
  }

  getUserTimeSlotList(addressId) {
    return this.http.get(`${this.url}/user/timeslotlist/${addressId}`, this.authService.getHttpOptions());
  }

  getUserAddressList(userId) {
    return this.http.get(`${this.url}/user/addresslist/${userId}`, this.authService.getHttpOptions());
  }

  getUserTypeDetailsList() {
    return this.http.get(`${this.url}/user/type/details`, this.authService.getHttpOptions());
  }

  getUserType(userTypeId: number) {
    return this.http.get(`${this.url}/user/type/${userTypeId}`, this.authService.getHttpOptions());
  }

  getUserTypeList() {
    return this.http.get(`${this.url}/user/type`, this.authService.getHttpOptions());
  }

  getUser(userId) {
    return this.http.get(`${this.url}/user/${userId}`, this.authService.getHttpOptions());
  }

  getUserList(params?: { id?: string, userTypeId?: string, email?: string, name?: string, phoneNumber?: string, createdAt?: string }, page?: StringPage) {
    return this.http.get(`${this.url}/user`, {
      headers: this.authService.getHttpHeaders(), params: {...params, ...page},
    });
  }
}
