import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListToMap } from '../../../../lib/lib-ngx/utils/ListToMap';
import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';

@Component({
  selector: 'lm-sav-event-modal',
  templateUrl: './sav-event-modal.component.html',
  styleUrls: ['./sav-event-modal.component.scss']
})
export class SavEventModalComponent implements OnInit {
  public incidentId;
  public incidentDetails = null;
  public lavandierMap = new Map();

  constructor(
    public activeModal: NgbActiveModal,
    private apiLavandierService: ApiLavandierService,
  ) {
  }

  ngOnInit() {
    this.resetData();
    this.loadData().subscribe();
  }

  resetData() {
    this.incidentDetails = null;
    this.lavandierMap = new Map();
  }

  loadData(): Observable<any> {
    return forkJoin([
      this.apiLavandierService.getIncidentDetails(this.incidentId), // todo must be replaced by the right route
      this.apiLavandierService.getLavandiers(),
    ]).pipe(
      map(([incidentDetails, lavandierList]: any[]) => {
        this.incidentDetails = incidentDetails;
        this.lavandierMap = ListToMap.convert(lavandierList.rows as any[]);
      })
    );
  }
}
