/// <reference types="@types/googlemaps" />
import { Component } from '@angular/core';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ErrorService } from '../lib/lib-ngx/services/error.service';
import { ConfirmationModalComponent } from './components/modals/confirmation-modal/confirmation-modal.component';
import { TimeoutLoginService } from '../lib/lib-ngx/services/timeout-login.service';

@Component({
  selector: 'lm-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    public errorService: ErrorService,
    public modalService: NgbModal,
    public timeoutLoginService: TimeoutLoginService,
  ) {
    moment.defineLocale('fr-lm', {
      parentLocale: 'fr',
      week: {dow: 0, doy: 6}
    });
    moment.locale('fr-lm');

    errorService.setModal((text: String) => {
      setTimeout(() => {
        const confirmationModal = modalService.open(ConfirmationModalComponent, {size: 'lg'});
        confirmationModal.componentInstance.confirmationDesc = text;
        confirmationModal.componentInstance.validateButtonText = 'Ok';
      });
    });

    timeoutLoginService.setModal(() => {
      const confirmationModal = modalService.open(ConfirmationModalComponent, {size: 'lg'});
      confirmationModal.componentInstance.confirmationDesc = 'Vous allez être déconnecté';
      confirmationModal.componentInstance.cancelButtonText = 'Annuler';
      confirmationModal.componentInstance.validateButtonText = 'Ok';
      return confirmationModal.result as Promise<boolean>;
    });
  }
}
