import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { TimeoutLoginService } from './timeout-login.service';

@Injectable({
  providedIn: 'root'
})
export class TimeoutLoginInterceptor implements HttpInterceptor {
  constructor(
    private timeoutLoginService: TimeoutLoginService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        event => this.timeoutLoginService.event(),
        error => this.timeoutLoginService.event(),
      )
    );
  }
}
