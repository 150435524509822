import { OrderArticleTrackingDefectType } from '../../../lib-shared/types/OrderArticleTrackingDefectType';

export const OrderArticleTrackingDefectTypeMap = new Map([
  [OrderArticleTrackingDefectType.STAIN, `Tâche`],
  [OrderArticleTrackingDefectType.DISCOLORATION_COLORATION, `Décoloration / coloration`],
  [OrderArticleTrackingDefectType.BURN, `Brûlure`],
  [OrderArticleTrackingDefectType.RIPPED, `Déchirure`],
  [OrderArticleTrackingDefectType.UNSTITCHED, `Décousue`],
  [OrderArticleTrackingDefectType.SHRINKAGE, `Rétrécissement`],
  [OrderArticleTrackingDefectType.MISSING_DAMAGED_ACCESSORY, `Accessoire manquant, endommagé`],
  [OrderArticleTrackingDefectType.WEAR, `Usure`],
  [OrderArticleTrackingDefectType.SENSITIVE_ARTICLE, `Article sensible avec accord client`],
]);
