import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, Validators } from '@angular/forms';
import { Standby } from '../../../../lib/lib-shared/types/Standby';

@Component({
  selector: 'lm-stand-by-modal',
  templateUrl: './stand-by-modal.component.html',
  styleUrls: ['./stand-by-modal.component.scss']
})
export class StandByModalComponent implements OnInit {
  public Standby = Standby;
  public standbyMap = new Map([
    [Standby.PRINT_PROBLEM, `Problème d'impression étiquette`],
    [Standby.DELETE_ARTICLE, `Supprimer un article`],
    [Standby.MISSING_ARTICLE, `Base de données article incomplète`],
    [Standby.REPRINT, `Réimprimer étiquette`],
    [Standby.OTHER, `Autres`],
  ]);

  public reasonFormControl = new FormControl('', Validators.required);

  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit() {
  }

  validateReason() {
    this.activeModal.close(this.reasonFormControl.value);
  }
}
