export class ListToMap {
  constructor() {
  }

  static convert(objectList: Object[]) {
    const result = new Map();
    objectList.forEach(function (object) {
      if (!object.hasOwnProperty('id')) {
        console.error('the object of objectLIst have to have an id property');
      } else {
        result.set(object['id'], object);
      }
    });
    return result;
  }

  static list(objectList: Object[], idKey: string) {
    const result = new Map();
    objectList.forEach(function (object) {
      if (!object.hasOwnProperty(idKey)) {
        console.error('the object of objectLIst have to have an id property');
      } else {
        const id = object[idKey];

        if (!result.has(id)) {
          result.set(id, []);
        }
        const list = result.get(id);
        list.push(object);
      }
    });
    return result;
  }
}
