import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ITransportSheetDetailsLast } from '../../../lib/lib-shared/models/TransportSheet';
import { ApiLavandierService } from '../../../lib/lib-ngx/web-services/api-lavandier.service';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'lm-factory-entrance',
  templateUrl: './factory-entrance.component.html',
  styleUrls: ['./factory-entrance.component.scss']
})
export class FactoryEntranceComponent implements OnInit {

  @ViewChild('transportSheetInput') public transportSheetInput: ElementRef;
  public transportSheetFormControl = new FormControl();

  public transportSheet: ITransportSheetDetailsLast = null;

  constructor(
    private apiLavandierService: ApiLavandierService,
  ) {
  }

  ngOnInit() {
    this.buildForm();
  }

  getTrackingSheetNumberInputFocus() {
    setTimeout(() => this.transportSheetInput.nativeElement.focus());
  }

  reset() {
    this.transportSheet = null;
    this.getTrackingSheetNumberInputFocus();
    this.transportSheetFormControl.reset();
  }

  buildForm() {
    this.transportSheetFormControl.valueChanges
      .pipe(debounceTime(200),
        distinctUntilChanged(),
        switchMap(ref => {
          this.transportSheet = null;
          if (ref && ref.trim().length) {
            return this.apiLavandierService.getTransportSheetRefDetails({ref: ref});
          }
          return of(null);
        }),
      )
      .subscribe((transportSheet: ITransportSheetDetailsLast) => {
        if (transportSheet) {
          this.transportSheet = transportSheet;
        } else {
          this.reset();
        }
      });

    this.reset();
  }

  onFactoryReceived() {
    this.apiLavandierService.putTransportSheetIdFactoryReceived(this.transportSheet.id, {})
      .subscribe(data => this.reset());
  }
}
