import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getValues'
})
export class GetValuesPipe implements PipeTransform { // todo replace when upgrading to angular >= 6.1 (with KeyValue Pipe)
  transform(map: Map<any, any>): any[] {
    const ret = [];

    map.forEach((val, key) => {
      ret.push({
        key: key,
        val: val
      });
    });

    return ret;
  }
}
