import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { AlertService, AlertType } from './alert.service';
import { AuthService } from '../web-services/auth.service';
import { ErrorMessage } from '../../lib-shared/ErrorMessage';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptorService implements HttpInterceptor {

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private router: Router,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        event => null,
        error => {
          if (error.hasOwnProperty('status')) {
            if (error.status === 401) {
              this.alertService.show(AlertType.WARNING, 'Vous ne disposez pas des droits pour cela.');
            } else if (error.status === 403) {
              if (error.hasOwnProperty('error') && error.error.hasOwnProperty('error') && error.error.error === ErrorMessage.INVALID_TOKEN) {
                this.authService.logout();
                this.router.navigate(['/login']);
              } else {
                this.alertService.show(AlertType.WARNING, 'Vous ne disposez pas des droits pour cela.');
                if (error.hasOwnProperty('error') && error.error.hasOwnProperty('stack') && error.error.stack.hasOwnProperty('permissionNameList')) {
                  console.log(error.error.stack.permissionNameList);
                }
              }
            } else if (error.status === 0) {
              this.alertService.show(AlertType.WARNING, 'Mise à jour en cours veuillez réessayer dans quelques instants.');
            } else if (error.hasOwnProperty('error') && error.error.hasOwnProperty('rawError') && error.error.rawError.hasOwnProperty('message')) {
              this.error(error.error.rawError.message);
            } else {
              this.error();
            }
          } else {
            this.error();
          }
        }
      )
    );
  }

  error(msg = 'Une erreur de communication avec le serveur s\'est produite.') {
    this.alertService.show(AlertType.ERROR, msg);
  }
}
