import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ApiLavandierService } from '../../../lib/lib-ngx/web-services/api-lavandier.service';
import { SavEventModalComponent } from '../modals/sav-event-modal/sav-event-modal.component';
import { IOrderBagDetailsList } from '../../../lib/lib-shared/models/OrderBag';

@Component({
  selector: 'lm-scan-bag',
  templateUrl: './scan-bag.component.html',
  styleUrls: ['./scan-bag.component.scss']
})
export class ScanBagComponent implements OnInit {

  @ViewChild('bagIdInput') public bagIdInput: ElementRef;
  public bagList: IOrderBagDetailsList[] = [];
  public selectedBag: IOrderBagDetailsList = null;
  public bagIdFormControl = new FormControl('');
  public orderIncident = null;

  constructor(
    private apiLavandierService: ApiLavandierService,
    private router: Router,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit() {
    this.initOrderBagRefList();
  }

  initOrderBagRefList() {
    this.bagIdFormControl.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe(
        ref => {
          if (ref.length) {
            this.apiLavandierService.getOrderBagTransportSheetRefDetails({ref: ref})
              .subscribe((data) => {
                this.bagList = data.orderBagList;
                this.orderIncident = data.incident;
                const selectedBag = this.bagList.find(bag => bag.transportSheet.ref === ref);
                this.selectedBag = selectedBag !== undefined ? selectedBag : null;
                if (this.orderIncident !== null) {
                  this.bagIdInput.nativeElement.blur();
                  this.onOpenSavEventModal();
                }
              });
          } else {
            this.reset();
          }
        }
      );
  }

  reset() {
    this.bagList = [];
    this.selectedBag = null;
    this.orderIncident = null;
  }

  getFinishedBagNumber() {
    let num = 0;
    this.bagList.forEach((bag) => {
      if (bag.finishAt) {
        num++;
      }
    });
    return num;
  }

  onOpenSavEventModal() {
    const savEventModal = this.modalService.open(SavEventModalComponent, {size: 'lg'});
    savEventModal.componentInstance.incidentId = this.orderIncident.id;
  }
}
