export enum OrderArticleTrackingDefectType {
  STAIN = 'STAIN',
  DISCOLORATION_COLORATION = 'DISCOLORATION_COLORATION',
  BURN = 'BURN',
  RIPPED = 'RIPPED',
  UNSTITCHED = 'UNSTITCHED',
  SHRINKAGE = 'SHRINKAGE',
  MISSING_DAMAGED_ACCESSORY = 'MISSING_DAMAGED_ACCESSORY',
  WEAR = 'WEAR',
  SENSITIVE_ARTICLE = 'SENSITIVE_ARTICLE',
}
